import React from "react"
import Footer from "../../../../components/Footer/Footer";
import SupportLeftNav from "../../../../components/TwoColumnLeftNav/SupportLeftNav";
import SoftwareDownloadButton from "../../../../components/SoftwareDownload/SoftwareDownloadButton";

const two_chLogger = () => {
    // useTranslations is aware of the global context (and therefore also "locale")
    // so it'll automatically give back the right translations

    return (
        <>
            <article className="company-info two-column-left-nav">
                <SupportLeftNav/>
                <section className="main-content">
                    <div className="content-container">
                        <h2 className={'large bold'}>2チャンネル温度ロガー SK-L210T用 USBドライバダウンロード</h2>

                        <div className="software-content">
                            <div className="terms-of-service-container">
                                <div className="content-pre">
                                    <p className="equal-line">＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>
                                    <p>このドキュメントは本製品に関する重要な情報について書かれています。<br/>
                                        ご使用の前に、必ずご一読くださるようお願いいたします。</p>
                                    <p className="equal-line">＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝＝</p>
                                </div>
                                <div className="content-title">
                                    <p>＜利用規約＞</p>
                                </div>
                                <div className="content-disclaimer">
                                    <div className="content-disclaimer-title">
                                        <p> ■免責事項</p>
                                    </div>
                                    <div className="content-disclaimer-content">
                                        <p>
                                            株式会社佐藤計量器製作所は本製品に関して動作確認はおこなっておりますが、全ての状況下において動作を保証しているわけではありません。<br/>
                                            本製品により生じた損害は、直接的および間接的損害を問わず、弊社に一切の賠償責任はないものとします。<br/>
                                            操作上のミス、注意を無視した操作、ご使用になられているＰＣに起因する不具合に対して、弊社は責任を負いかねますのでご了承ください。<br/><br/>
                                            ※こちらで提供いたしております各種ソフトウェアは、日本国内で販売されている各製品と日本語版OSの組み合わせのみ、動作確認をおこなっております。<br/>
                                            海外版OSとの組み合わせにて発生したトラブルに関しましては、一切保証およびサポートいたしかねますのでご了承ください。
                                        </p>
                                    </div>
                                </div>
                                <div className="content-copyright">
                                    <div className="content-copyright-title">
                                        <p> ■著作権</p>
                                    </div>
                                    <div className="content-copyright-content">
                                        <ol>
                                            <li>
                                                「SK-L210T Software」のプログラムおよび関連ドキュメントに関する著作権は株式会社佐藤計量器製作所に帰属します。
                                            </li><br/>
                                            <li>
                                                「SK-L210T Software」は以下条件をもとに、無償でご利用いただけます。
                                                <ul>
                                                    <li>
                                                        <p>逆コンパイル・ファイル解析、改変等を禁じます。</p>
                                                    </li>
                                                    <li>
                                                        <p>本プログラムの使用による損害は、弊社は一切の責任を負いません。</p>
                                                    </li>
                                                    <li>
                                                        <p>再配布は自由としますが、企業内・企業間、その他関係においても営利目的にしないこと。<br/>
                                                            再配布したプログラムの使用による損害に対しても弊社は一切の責任を負いません。</p>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>
                                        <p>本利用規約に同意いただけた方にのみ、以下ソフトウェアをご利用いただけます。</p>
                                    </div>
                                </div>
                                <div className="content-feature">
                                    <div className="content-feature-title">
                                        <p> ■ソフトウェアの特徴</p>
                                    </div>
                                    <div className="content-feature-content">
                                        <ol>
                                            <li>
                                                <p>SK-L210T Software</p>
                                                <p>
                                                    「SK-L210T Software」は、2チャンネル温度ロガー「SK-L210T」本体の各設定、データの解析をおこなうものです。2チャンネル温度ロガーで測定したデータは、「SK-L210T Software」を用いてパソコンへ回収し、グラフ表示・印刷およびデータ解析がおこなえます。
                                                    <br/>
                                                    本ダウンロードページではパソコンと2チャンネル温度ロガーを接続するために必要なUSBドライバのWindows7対応版をダウンロードできます。
                                                </p>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="way-to-install">
                                <div className="install-title">
                                    <p className="bold">【インストール方法】</p>
                                </div>
                                <div className="install-content">
                                    <p>
                                        「<a href="https://d3hdfc67hsdxgb.cloudfront.net/corporate/pdfs/install.pdf">インストールの手順.pdf</a>」に添ってインストールをおこなってください。
                                    </p>
                                    <p className="important-text">(インストール前に必ずお読みください。)</p>
                                    <p>
                                        ダウンロードファイル「USB Driver_Windows7.zip」はUSBドライバです。
                                        <br/>
                                        解析ソフトウェアはCD-ROMに収録されている「SK-L210T Software V1.0」を使用してください。
                                        <br/>
                                        ダウンロード項目にあります、「インストールの手順.pdf」をお読みになり、インストールをおこなってください。
                                    </p>
                                    <p>
                                        ご注意：
                                        <br/>
                                        旧バージョンの解析ソフトウェアおよびUSBドライバがインストール済みの場合、ダウンロードしたUSBドライバをインストールする前に、必ず旧バージョンのアンインストールをおこなってください。
                                    </p>
                                </div>
                            </div>
                            <div className="download">
                                <div className="download-title">
                                    <p className="bold">【ダウンロード】</p>
                                </div>
                                <div className="download-content">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <th>
                                                <p className="download-table-title">ソフトウェア名</p>
                                            </th>
                                            <td>
                                                <p>SK-L210T用 USBドライバ</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <p className="download-table-title">対象機器</p>
                                            </th>
                                            <td>
                                                <p>SK-L210T</p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <p className="download-table-title">対象OS</p>
                                            </th>
                                            <td>
                                                <p>Windows7</p>
                                                <p>
                                                    64bit/32bit版
                                                    <br/>
                                                    ※動作検証はProfessional 64bit及び、32bit版にておこなっています。
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <p className="download-table-title">ダウンロード</p>
                                            </th>
                                            <td>
                                                <SoftwareDownloadButton
                                                    yourKey="corporate/software/usbdriver_windows7.zip"
                                                    tmpKey="software/usbdriver_windows7.zip"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                <p className="download-table-title">バージョンアップ内容</p>
                                            </th>
                                            <td>
                                                <p>Windows 7に対応しました。</p>
                                                <p>
                                                    ※注意
                                                    <br/>
                                                    解析ソフトウェアは付属のCD-ROMに収録されている「SK-L210T Software V1.0」を使用してください。
                                                </p>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </article>
            <Footer/>
        </>
    )
}

export default two_chLogger